import RendererBase from './RendererBase';

/**
 * Renderer that updates slots without any animation
 */
export default class ReplaceRenderer extends RendererBase {
  /**
   * Updates the rendering context
   * @param {Duration}
   */
  update(duration) {
    Object.keys(duration)
      .forEach((slot) => {
        const value = parseInt(duration[slot], 10),
          slotEl = this.element.querySelector(`[data-slot="${slot}"]`);
        if (slotEl && parseInt(slotEl.dataset.value, 10) !== value) {
          slotEl.querySelector('.value').innerHTML = value;
          slotEl.dataset.value = value;

          const label = this.durations[slot][value <= 1 ? 'singular' : 'plural'];
          if (slotEl.querySelector('.label').innerHTML !== label) {
            slotEl.querySelector('.label').innerHTML = label;
          }
        }
      });
  }
}
