import './Counter.less';

/**
 * Available options of preferred direction of numbers to change
 */
const DIRECTION = {
  /** Option for counters expecting numbers to be increased */
  ASCENDING: 'asc',

  /** Option for counters expecting numbers to be decreased */
  DESCENDING: 'desc',

  /** Option for counters with no preferred direction */
  BIDIRECTIONAL: 'bi'
};

export default class Counter extends HTMLElement {
  #direction

  #current = 0
  #target = 0

  #container
  #speed
  #itemHeight

  set current(value) {
    if (!Number.isNaN(value)) {
      this.#current = value;
    }
  }

  get current() {
    return this.#current;
  }

  set direction(dir) {
    if (dir !== this.direction && Object.values(DIRECTION).indexOf(dir) > -1) {
      this.#direction = dir;
    }
  }

  get direction() {
    return this.#direction;
  }

  constructor(direction) {
    super();
    this.direction = direction;
  }

  connectedCallback() {
    this.#render();

    const
      style = window.getComputedStyle(this),
      speed = style.getPropertyValue('--speed'),
      { direction } = this.dataset;

    this.#speed = parseFloat(speed) * (/\ds$/.test(speed) ? 1000 : 1);
    this.#itemHeight = parseFloat(style.getPropertyValue('--size')) * parseFloat(style.getPropertyValue('--aspect-ratio'));

    if (direction) {
      this.#direction = direction;
    }
  }

  increment(amount) {
    if (!Number.isNaN(amount) && parseInt(amount, 10) !== 0) {
      this.countTo(this.#current + amount);
    }
  }

  countTo(number) {
    if (number !== this.#current) {
      this.#target = number;
      this.#update();
    }
  }

  #render() {
    this.#container = document.createElement('div');
    this.#container.className = 'animation-container';

    this.#renderItem(this.current);

    this.appendChild(this.#container);
  }

  #renderItem(value) {
    const item = document.createElement('div');
    item.className = 'counter-item';
    item.innerHTML = value;
    item.dataset.value = value;

    this.#container.appendChild(item);

    return item;
  }

  #update() {
    const { style } = this.#container;

    style.top = '0px';
    style.bottom = 'auto';
    style.transitionProperty = 'top';

    const values = this.#values();
    if (values.length) {
      values.map((value) => this.#renderItem(value));

      this.#container.classList.add('animating');

      this.#container.style.top = `${this.#itemHeight - this.#container.offsetHeight}px`;

      setTimeout(() => {
        this.current = this.#target;

        this.#container.classList.remove('animating');

        this.#container.classList.remove('stick-to-top');
        this.#container.classList.add('stick-to-bottom');

        style.top = 'auto';
        style.bottom = '0px';
        style.transitionProperty = 'bottom';

        [...this.#container.childNodes]
          .filter((node) => parseFloat(node.dataset.value) !== this.current)
          .forEach((node) => node.remove());
      }, this.#speed);
    }
  }

  #values() {
    if (this.#direction === DIRECTION.ASCENDING && this.#target < this.current) {
      return [this.#target];
    }

    if (this.#direction === DIRECTION.DESCENDING && this.#target > this.current) {
      return [this.#target];
    }

    const n = parseInt(Math.abs(this.#target - this.current), 10),
      direction = this.#target > this.current ? DIRECTION.ASCENDING : DIRECTION.DESCENDING;

    return [...Array(n).keys()]
      .map((i) => (direction === DIRECTION.ASCENDING
        ? this.current + i + 1
        : this.current - i - 1));
  }
}

window.customElements.define('nj-counter', Counter);
