/**
 * Base class for renderers that updates slots
 */
export default class RendererBase {
  element

  durations = {
    seconds: { plural: 'seconds', singular: 'second' },
    minutes: { plural: 'minutes', singular: 'minute' },
    hours: { plural: 'hours', singular: 'hour' },
    days: { plural: 'days', singular: 'day' },
    months: { plural: 'months', singular: 'month' },
    years: { plural: 'years', singular: 'year' }
  };

  /**
   * @param {HTMLElement} element Rendering container
   * @param {string} maxDuration Largest duration component to display
   */
  constructor(element, maxDuration) {
    this.element = element;

    this.render(maxDuration);
  }

  /**
   * Initialises the rendering context
   * @param {string} maxDuration Largest duration component to be displayed
   */
  render(maxDuration) {
    const slots = Object.keys(this.durations),
      maxIdx = slots.indexOf(maxDuration);

    slots
      .filter((slot, idx) => idx <= maxIdx)
      .reverse()
      .forEach((slot) => {
        const slotEl = document.createElement('div');
        slotEl.setAttribute('data-slot', slot);
        this.element.appendChild(slotEl);

        const valueEl = document.createElement('span');
        valueEl.className = 'value';
        slotEl.appendChild(valueEl);

        const labelEl = document.createElement('span');
        labelEl.className = 'label';
        labelEl.innerHTML = slot;
        slotEl.appendChild(labelEl);
      });
  }

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["update"] }] */
  /**
   * Updates the rendering context
   * @param {Duration}
   */
  update(duration) { }
}
