/**
 * Type of duration the Countdown component can display
 */
export const DURATION = {
  /**
   * Duration component representing seconds
   */
  SECONDS: 'seconds',

  /**
   * Duration component representing minutes
   */
  MINUTES: 'minutes',

  /**
   * Duration component representing hours
   */
  HOURS: 'hours',

  /**
   * Duration component representing days
   */
  DAYS: 'days',

  /**
   * Duration component representing months
   */
  MONTHS: 'months',

  /**
   * Duration component representing years
   */
  YEARS: 'years'
};

/**
 * Available rendering methods
 */
export const RENDERING = {
  /**
   * New numbers replaces the previous one
   */
  REPLACE: 'replace',

  /**
   * New numbers pushes the previous one up
   */
  SLIDE_UP: 'slide-up'
};
