import {
  differenceInCalendarDays,
  differenceInHours, differenceInMinutes, differenceInSeconds,
  intervalToDuration, isFuture
} from 'date-fns';
import { DURATION, RENDERING } from './constants';
import './Countdown.less';
import ReplaceRenderer from './renderers/ReplaceRenderer';
import SlideUpRenderer from './renderers/SlideUpRenderer';

/**
 * Component displaying slots for counting down to a given date
 */
export default class Countdown extends HTMLElement {
  #date
  #maxDuration
  #intervalId
  #rendering
  #renderer

  /**
   * @inheritDoc
   */
  connectedCallback() {
    this.#date = new Date(this.getAttribute('datetime'));
    this.#maxDuration = this.getAttribute('max-duration') || DURATION.MONTHS;
    this.#rendering = this.getAttribute('rendering') || RENDERING.REPLACE;

    if (this.#isValid() && isFuture(this.#date)) {
      this.start();
    }
  }

  /**
   * Displays the counter and updates it in every second
   */
  start() {
    if (!this.#renderer) {
      this.#render();
    }

    this.#intervalId = setInterval(() => {
      this.#renderer.update(this.#duration());
    }, 1000);
  }

  /**
   * Pauses the counter to be updated
   */
  stop() {
    clearInterval(this.#intervalId);
  }

  #render() {
    const container = document.createElement('div');

    switch (this.#rendering) {
      case RENDERING.REPLACE:
        this.#renderer = new ReplaceRenderer(container, this.#maxDuration);
        break;

      case RENDERING.SLIDE_UP:
        this.#renderer = new SlideUpRenderer(container, this.#maxDuration);
        break;

      default:
        throw Error(`Unrecognised rendering method: ${this.#rendering}`);
    }

    this.appendChild(container);
  }

  #duration() {
    const now = new Date(),
      interval = {
        start: now,
        end: this.#date
      },
      duration = intervalToDuration(interval);

    switch (this.#maxDuration) {
      case DURATION.MONTHS:
        duration.months += duration.years * 12;
        duration.years = 0;
        break;

      case DURATION.DAYS:
        duration.days = differenceInCalendarDays(interval.end, interval.start);
        duration.months = 0;
        duration.years = 0;
        break;

      case DURATION.HOURS:
        duration.hours = differenceInHours(interval.end, interval.start);
        duration.days = 0;
        duration.months = 0;
        duration.years = 0;
        break;

      case DURATION.MINUTES:
        duration.minutes = differenceInMinutes(interval.end, interval.start);
        duration.hours = 0;
        duration.days = 0;
        duration.months = 0;
        duration.years = 0;
        break;

      case DURATION.SECONDS:
        duration.seconds = differenceInSeconds(interval.end, interval.start);
        duration.minutes = 0;
        duration.hours = 0;
        duration.days = 0;
        duration.months = 0;
        duration.years = 0;
        break;

      default:
        throw Error(`Invalid duration: ${this.#maxDuration}`);
    }

    return duration;
  }

  #isValid() {
    return this.#date instanceof Date && !Number.isNaN(this.#date);
  }
}

window.customElements.define('nj-countdown', Countdown);
