import Counter from '../Counter';
import RendererBase from './RendererBase';

/**
 * Renderer that uses Counters for updating slots
 */
export default class SlideUpRenderer extends RendererBase {
  /**
   * Initialises the rendering context
   * @param {string} maxDuration Largest duration component to be displayed
   */
  render(maxDuration) {
    super.render(maxDuration);

    this.element.querySelectorAll('.value')
      .forEach((valueEl) => {
        const counter = new Counter();
        counter.classList.add('default');
        valueEl.appendChild(counter);
      });
  }

  /**
   * Updates the rendering context
   * @param {Duration}
   */
  update(duration) {
    Object.keys(duration)
      .forEach((slot) => {
        const value = parseInt(duration[slot], 10),
          slotEl = this.element.querySelector(`[data-slot="${slot}"]`);

        if (slotEl && (parseInt(slotEl.dataset.value, 10) !== value)) {
          this.renderValue(slotEl.querySelector('nj-counter'), value);
          slotEl.dataset.value = value;

          const label = this.durations[slot][value <= 1 ? 'singular' : 'plural'];
          if (slotEl.querySelector('.label').innerHTML !== label) {
            slotEl.querySelector('.label').innerHTML = label;
          }
        }
      });
  }

  /* eslint class-methods-use-this: ["error", { "exceptMethods": ["renderValue"] }] */
  /* eslint no-param-reassign: ["error", { "props": false }] */
  /**
   * Renders the animation container
   * @param {HTMLElement} container
   * @param {Number} value
   */
  renderValue(container, value) {
    container.countTo(value);

    container.direction = 'desc';
  }
}
